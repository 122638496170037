<template>
    <div class="privacy-policy">
      <vue-pdf-embed :source="source"></vue-pdf-embed>
    </div>
  </template>
  
  <script>
  import VuePdfEmbed from 'vue-pdf-embed'
  
  export default {
    components: {
      VuePdfEmbed,
    },
    data() {
      return {
        source:
          'https://u8db3x7279cw66r74nmk.s3.eu-west-3.amazonaws.com/Mentions+L%C3%A9gales-Politique+de+confidentialit%C3%A9-Cookies.pdf',
      }
    },
  }
  </script>
  
  <style scoped lang="scss">
  .privacy-policy {
    max-width: 1000px;
    width: 95%;
    margin: auto;
  }
  </style>
  